<template>
  <div :class="{'late-background': item.delay_type === 1, 'delay-background': item.delay_type === 2}" class="w-full flex rounded-lg shadow-lg px-9 py-4 my-0.5">
    <div v-if="screenMode.withScreen && item.Photo !== undefined" class="flex flex-row justify-center items-center w-1/6">
      <img class="h-full w-8/12 bg-white" :src="buildPhotoStr(item.Photo)" alt="suit">
    </div>
    <div :class="{'w-1/5': !screenMode.withScreen , 'w-1/6': screenMode.withScreen}" class="flex flex-row justify-center items-center">
      <span :class="{'py-4': !screenMode.withScreen, 'text-white': item.delay_type != 0, 'text-gray-800': item.delay_type === 0}" class="font-roboto font-normal 2xl:text-2xl xl:text-lg lg:text-lg text-xs text-center">{{decodeURI(item.tovar_name)}}</span>
    </div>
    <div :class="{'w-1/5': !screenMode.withScreen , 'w-1/6': screenMode.withScreen}" class="flex flex-row justify-center items-center">
      <span :class="{'py-4': !screenMode.withScreen, 'text-white': item.delay_type != 0, 'text-gray-800': item.delay_type === 0}" class="font-roboto font-normal 2xl:text-2xl xl:text-lg lg:text-lg text-xs">{{item.doc_num}}</span>
    </div>
    <div :class="{'w-1/5': !screenMode.withScreen , 'w-1/6': screenMode.withScreen}" class="flex flex-row justify-center items-center">
      <span :class="{'py-4': !screenMode.withScreen, 'text-white': item.delay_type != 0, 'text-gray-800': item.delay_type === 0}" class="font-roboto font-normal 2xl:text-2xl xl:text-lg lg:text-lg text-xs">{{decodeURI(item.barcode_read )}}</span>
    </div>
    <div :class="{'w-1/5': !screenMode.withScreen , 'w-1/6': screenMode.withScreen}" class="flex flex-row justify-center items-center">
      <span :class="{'py-4': !screenMode.withScreen, 'text-white': item.delay_type != 0, 'text-gray-800': item.delay_type === 0}" class="font-roboto font-normal 2xl:text-2xl xl:text-lg lg:text-lg text-xs">{{item.doc_date}}</span>
    </div>
    <div :class="{'w-1/5': !screenMode.withScreen , 'w-1/6': screenMode.withScreen}" class="flex flex-row justify-center items-center">
      <span :class="{'py-4': !screenMode.withScreen, 'text-white': item.delay_type != 0, 'text-gray-800': item.delay_type === 0}" class="font-roboto font-normal 2xl:text-2xl xl:text-lg lg:text-lg text-xs">{{decodeURI(item.date_out).split(' ')[0]}}</span>
    </div>
  </div>
</template>

<script>
import globalParams from "@/mixins/globalParams.mixin.js";
export default {
    name: "ServiceItem",
    mixins: [globalParams],
    props : {
      item: {
        type: Object,
        required: true
      }
    },
    methods: {
      buildPhotoStr(arr){
        if(arr.length === 0) {
          return require('@/assets/images/empty.png');
        }
        else {
          return 'data:image/png;base64,' + arr[0].img;
        }
      }
    }

}
</script>

<style>
  .delay-background {
    background: radial-gradient(121% 895.52% at 36.01% 0%, #DB9E00 0%, #FF9900 96.88%),
    linear-gradient(0deg, #FFFFFF, #FFFFFF);
  }

  .late-background {
    background: radial-gradient(121% 895.52% at 36.01% 0%, #DB0000 0%, #FF004D 96.88%),
    linear-gradient(0deg, #FFFFFF, #FFFFFF);
  }
</style>

<template>
  <div v-if="screenMode.withScreen || screenMode.withoutScreen" class="w-full h-screen">
    <div class="w-full h-1/4 bg-white flex flex-col justify-center items-center fixed">
      <div class="w-full container mx-auto flex justify-center ">
        <div class="w-1/3 flex flex-col justify-center items-start">
        <span class="2xl:text-xl xl:text-lg text-lg p-4 text-blue-900 font-bold">
          Секунд до обновления: {{timeLeft}}
        </span>
        </div>
        <div class="w-1/3 flex flex-row justify-center items-center">
          <div class="p-4">
            <span class="text-4xl font-bold text-blue-900 uppercase font-roboto mr-0.5">монитор</span>
            <span class="text-4xl font-bold text-black uppercase font-roboto">цеха</span>
          </div>
        </div>
        <div class="w-1/3 flex flex-row justify-end items-center">
          <span @click="goToParams()" class="text-xl font-bold text-blue-900 font-roboto mr-0.5 cursor-pointer p-4">Настройки</span>
        </div>
      </div>
      <div class="w-full flex container mx-auto py-5">
        <div class="bg-red-300 rounded-md mr-20">
          <button style="background: radial-gradient(121% 895.52% at 36.01% 0%, #CC0000 0%, #D3003F 96.88%);" class="cursor-default text-white 2xl:text-xl xl:text-xl lg:text-lg md:text-md text-xs font-roboto font-bold rounded-md rounded-br-3xl px-4 py-3 focus:outline-none">Просроченные : {{overdue}}</button>
        </div>
        <div class="bg-yellow-100 rounded-md mr-20">
          <button  style="background: radial-gradient(121% 895.52% at 36.01% 0%, #EADA00 0%, #FFA800 96.88%)" class="cursor-default text-white 2xl:text-xl xl:text-xl lg:text-lg md:text-md text-xs font-roboto font-bold rounded-md rounded-br-3xl px-4 py-3 focus:outline-none">Опаздывающие : {{lingering}}</button>
        </div>
        <div class="bg-green-100 rounded-md mr-20">
          <button style="background: radial-gradient(121% 895.52% at 36.01% 0%, #00A040 0%, #00DA71 96.88%);" class="cursor-default text-white 2xl:text-xl xl:text-xl lg:text-lg md:text-md text-xs font-roboto font-bold rounded-md rounded-br-3xl px-4 py-3 focus:outline-none">В работе : {{inWork}}</button>
        </div>
      </div>
      <div class="w-full flex container mx-auto rounded-t-lg bg-blue-900 px-9 py-4 mt-4">
        <div :class="{'w-1/5': !screenMode.withScreen , 'w-1/6': screenMode.withScreen}" v-if="screenMode.withScreen" class="flex flex-row justify-center items-center">
          <span class="text-white font-roboto font-medium 2xl:text-2xl xl:text-lg lg:text-lg text-xs">Фото услуги</span>
        </div>
        <div :class="{'w-1/5': !screenMode.withScreen , 'w-1/6': screenMode.withScreen}" class="flex flex-row justify-center items-center">
          <span class="text-white font-roboto font-medium 2xl:text-2xl xl:text-lg lg:text-lg text-xs">Услуга</span>
        </div>
        <div :class="{'w-1/5': !screenMode.withScreen , 'w-1/6': screenMode.withScreen}" class="flex flex-row justify-center items-center">
          <span class="text-white font-roboto font-medium 2xl:text-2xl xl:text-lg lg:text-lg text-xs">Номер заказа</span>
        </div>
        <div :class="{'w-1/5': !screenMode.withScreen , 'w-1/6': screenMode.withScreen}" class="flex flex-row justify-center items-center">
          <span class="text-white font-roboto font-medium 2xl:text-2xl xl:text-lg lg:text-lg text-xs">Номер бирки</span>
        </div>
        <div :class="{'w-1/5': !screenMode.withScreen , 'w-1/6': screenMode.withScreen}" class="flex flex-row justify-center items-center">
          <span class="text-white font-roboto font-medium 2xl:text-2xl xl:text-lg lg:text-lg text-xs">Прием</span>
        </div>
        <div :class="{'w-1/5': !screenMode.withScreen , 'w-1/6': screenMode.withScreen}" class="flex flex-row justify-center items-center">
          <span class="text-white font-roboto font-medium 2xl:text-2xl xl:text-lg lg:text-lg text-xs">Выдача</span>
        </div>
      </div>
    </div>
    <div class="w-full h-1/4"></div>
    <div class="w-full h-full container mx-auto flex flex-col justify-start items-center">
      <div class="w-full" v-if="services.length>0">
        <service-item v-for="current in services" :key="current" :item="current" />
      </div>
    </div>
  </div>
  <div class="flex flex-col justify-start items-center" v-else>
    <div class="w-full h-20 bg-white flex flex-col justify-center items-center fixed">
      <div class="w-full container mx-auto flex justify-center">
        <div class="w-1/3 flex flex-col justify-center items-start">
          <span class="text-xl text-blue-900 font-bold">
            Секунд до обновления: {{timeLeft}}
          </span>
        </div>
        <div class="w-1/3 flex flex-row justify-center items-center">
          <div>
            <span class="text-4xl font-bold text-blue-900 uppercase font-roboto mr-0.5">монитор</span>
            <span class="text-4xl font-bold text-black uppercase font-roboto">цеха</span>
          </div>
        </div>
        <div class="w-1/3 flex flex-row justify-end items-center">
          <span @click="goToParams()" class="text-xl font-bold text-blue-900 font-roboto mr-0.5 cursor-pointer">Настройки</span>
        </div>
      </div>
    </div>
    <div class="w-full h-20"></div>
    <div class="w-full h-full flex flex-row justify-start items-start">
      <div class="w-2/3">
        <div class="w-full flex rounded-lg bg-blue-900 px-9 py-4 mt-4">
          <div class="flex flex-row justify-center items-center w-1/2">
            <span class="text-white font-roboto font-medium text-lg">Дата выдачи</span>
          </div>
          <div class="flex flex-row justify-center items-center w-1/2">
            <span class="text-white font-roboto font-medium text-lg">Количество услуг в работе</span>
          </div>
        </div>
        <div v-for="item in statisticList" :key="item" class="w-full flex rounded-lg shadow-lg px-9 py-4 my-0.5">
          <div class="flex flex-row justify-center items-center w-1/2 py-4">
            <span class="text-gray-800 font-roboto font-normal text-2xl">{{ item }}</span>
          </div>
          <div class="flex flex-row justify-center items-center w-1/2 py-4">
            <span class="text-gray-800 font-roboto font-normal text-2xl">{{ services.filter(cur => cur.date_out.split('%')[0] === item).length }}</span>
          </div>
        </div>
      </div>
      <div class="w-1/3 flex flex-col justify-center items-start py-5">
        <div class="bg-red-300 rounded-md 2xl:ml-20 xl:ml-10 ml-5 mb-2">
          <button style="background: radial-gradient(121% 895.52% at 36.01% 0%, #CC0000 0%, #D3003F 96.88%);" class="cursor-default text-white 2xl:text-xl xl:text-lg text-md font-roboto font-bold rounded-md rounded-br-3xl w-64 px-10 py-3.5 focus:outline-none">Просроченные: {{overdue}}</button>
        </div>
        <div class="bg-yellow-100 rounded-md 2xl:ml-20 xl:ml-10 ml-5 mb-2">
          <button  style="background: radial-gradient(121% 895.52% at 36.01% 0%, #EADA00 0%, #FFA800 96.88%)" class="cursor-default text-white 2xl:text-xl xl:text-lg text-md font-roboto font-bold rounded-md rounded-br-3xl w-64 py-3.5 focus:outline-none">Опаздывающие: {{lingering}}</button>
        </div>
        <div class="bg-green-100 rounded-md 2xl:ml-20 xl:ml-10 ml-5 mb-2">
          <button style="background: radial-gradient(121% 895.52% at 36.01% 0%, #00A040 0%, #00DA71 96.88%);" class="cursor-default text-white 2xl:text-xl xl:text-lg text-md font-roboto font-bold rounded-md rounded-br-3xl w-64 py-3.5 focus:outline-none">В работе: {{inWork}}</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import globalParams from "@/mixins/globalParams.mixin.js";
import ServiceItem from "@/components/ServiceItem.vue";
export default {
    name: 'Params',
    mixins: [globalParams],
    data(){
      return {
        timeLeft: 0,
        requestMethod: null,
        timerMethod: null
      }
    },
    components: {
      ServiceItem
    },
    methods: {
      goToParams(){
        this.$router.push('/params');
        this.unmount;
      },
      intervalRequest() {
        this.requestMethod = setInterval(() => {
          this.getData({
            securecode: this.securecode,
            sessionID: this.sessionID,
            refreshID: this.refreshID,
            withPhoto: this.screenMode.withScreen === true ? 1 : 0
          });
          this.timeLeft = this.updateParametr/1000;
        },this.updateParametr);
      },
      intervalTimer(){
        this.timerMethod = setInterval(() => {
          this.timeLeft--;
        },1000)
      }
    },
    beforeMount() {

      this.intervalRequest();

      this.timeLeft = this.updateParametr/1000;

      this.intervalTimer();

    },
    beforeUnmount() {
      clearInterval(this.timerMethod);
      clearInterval(this.requestMethod);
    }
}
</script>
